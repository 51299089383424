
import {computed, defineComponent, ref, watch} from "vue";
import {useGroupStore} from "@/store/group.store";
import GroupService from "../../core/services/GroupService";
import store from "../../store";
import {Actions} from "@/store/enums/StoreEnums";
import BaseForm from "../../components/base/form/BaseForm.vue";
import FormErrorMessage from "../../components/base/form/FormErrorMessage.vue";
import {Field} from "vee-validate";
import DocCollections from "@/views/workspace/DocCollections.vue";
import MetricGroups from "@/views/workspace/MetricGroups.vue";
import TaskTemplates from "@/views/task/template/TaskTemplates.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import HelpEmbed from "@/components/base/HelpEmbed.vue";
import InfoBox from "@/components/base/InfoBox.vue";
import SpaceTraining from "@/views/workspace/SpaceTraining.vue";

export default defineComponent({
  name: "Setting",
  components: {
    SpaceTraining,
    InfoBox,
    HelpEmbed, BaseRadio, TaskTemplates, MetricGroups, DocCollections, FormErrorMessage, BaseForm, Field
  },
  setup() {
    const submitting = ref(false);
    const groupStore = useGroupStore();
    const model = ref<any>({
      name: '',
      config: {work: true, metric: true, task: true, timer: false, documentLibrary: true}
    });
    const group = computed<any>(() => groupStore.entity);
    model.value = {
      name: group.value?.name,
      privacy: group.value.privacy,
      config: group.value.config ? group.value.config : {
        work: true,
        metric: true,
        task: true,
        timer: false,
        documentLibrary: true
      }
    }
    watch(group, cb => {
      model.value = {
        name: cb.name,
        privacy: cb.privacy,
        config: cb.config ? cb.config : {work: true, metric: true, task: true, timer: false, documentLibrary: true}
      }

    })
    return {
      model,
      submitting,
      group,
      groupStore,
    }
  },
  methods: {
    save() {
      this.submitting = true;
      GroupService.update(this.group.id, this.model).then(res => {
        this.groupStore.setEntity(res);
      }).finally(() => {
        store.dispatch(Actions.LOAD_PROFILE)
        this.submitting = false;
      })
    }
  }
})
