
import {defineComponent, ref, watch} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {Field} from "vee-validate";
import {TASK_TEMPLATE_TYPE} from "@/core/config/Constant";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import BaseCheckBox from "@/components/base/checkbox/BaseCheckBox.vue";
import TaskTemplateService from "@/core/services/TaskTemplateService"
import {BreakDownSelect, GetSpaceId, TeamSelect} from "@/core/composite/composite";
import DocSelect from "@/views/doc/DocSelect.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";

export default defineComponent({
  name: "TaskTemplateForm",
  components: {UserListSelect, BaseRadio, DocSelect, BaseCheckBox, BaseSelect, FormErrorMessage, BaseForm, Field},
  props: {
    id: {type: String},
    data: {},
  },
  emits: ['save'],
  setup(props) {
    const submitting = ref(false);
    const model = ref<any>(props.data);
    watch(() => props.data, (cb) => {
      model.value = cb;
    });
    const addLineInput = () => {
      model.value.inputs.push({name: '', type: 'TEXT', required: false, breakdownId: ''})
    }
    const removeLineInput = (line) => {
      model.value.inputs = model.value.inputs.filter(item => item !== line)
    }

    const addLineResult = () => {
      model.value.results.push({name: '', type: 'TEXT', required: false, breakdownId: ''})
    }
    const removeLineResult = (line) => {
      model.value.results = model.value.results.filter(item => item !== line)
    }
    return {
      addLineResult,
      removeLineResult,
      removeLineInput,
      submitting,
      model,
      TASK_TEMPLATE_TYPE,
      addLineInput,
      ...GetSpaceId(),
      ...BreakDownSelect(),
      ...TeamSelect(),
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      if (this.id) {
        TaskTemplateService.update(this.id, this.model).then(() => {
          this.$emit('save')
        }).finally(() => {
          this.submitting = false;
        })
      } else {
        TaskTemplateService.create(this.model).then(() => {
          this.$emit('save')
        }).finally(() => {
          this.submitting = false
        })
      }
    }
  }
})
