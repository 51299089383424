
import {useTaskTemplateStore} from "@/store/task-template.store";
import {computed, defineComponent, ref} from "vue";
import {LoadPanel, PinaFilterObjects, TemplateSelect} from "@/core/composite/composite";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import TaskTemplateForm from "@/views/task/template/TaskTemplateForm.vue";
import Swal from "sweetalert2";
import TaskTemplateService from "@/core/services/TaskTemplateService";
import TaskTemplateStatus from "@/views/task/template/TaskTemplateStatus.vue";

export default defineComponent({
  name: "TaskTemplates",
  components: {TaskTemplateStatus, TaskTemplateForm, BaseModal, QuickAction, KtDatatable},
  props: {
    workspaceId: {}
  },
  setup(props) {
    const state = ref<any>({
      id: '',
      name: 'Add Template',
      model: {
        name: '',
        inputs: [],
        results: [],
        workspaceIdRequest: '',
        approvalRequired: false,
        docIdRequest: '',
        assignType: 'USER',
        assignedIdRequest: '',
        approvalType: 'TASK_CREATOR',
        approvalIdRequest: ''
      },
      templateId: ''
    })

    const store = useTaskTemplateStore();
    const page = computed(() => store.page)
    const filterObjects = ref({workspaceId: props.workspaceId});
    const headers = [
      {
        name: "Name",
        key: "name",
        sortable: false,
      },
      {
        name: "Status",
        key: "status",
        sortable: false,
      },
      {
        name: '',
        key: 'actions',
        sortable: false,
      }
    ]
    return {
      headers,
      page,
      filterObjects,
      state,
      ...PinaFilterObjects(store, filterObjects.value, []),
      ...LoadPanel(),
      ...TemplateSelect(),
    }
  },
  watch: {
    workspaceId(cb) {
      this.filterObjects.workspaceId = cb;
      this.updateFilterObject(this.filterObjects);
    }
  },
  methods: {
    add() {
      const baseModal = this.$refs.templateModalRef as typeof BaseModal
      this.state.name = 'Add Template';

      this.state.model = {
        name: '',
        inputs: [],
        results: [],
        docIdRequest: '',
        workspaceIdRequest: this.workspaceId,
        approvalRequired: false,
        assignType: 'USER',
        assignedIdRequest: '',
        approvalType: 'TASK_CREATOR',
        approvalIdRequest: ''
      }

      this.state.id = '';
      baseModal.showBaseModal();
    },

    onEdit(template) {
      const baseModal = this.$refs.templateModalRef as typeof BaseModal
      this.state.name = 'Edit Template';
      this.state.id = template.id

      this.state.model = {
        docIdRequest: template.docId,
        name: template.name,
        results: template.results,
        inputs: template.inputs,
        approvalRequired: template.approvalRequired,
        assignType: template.assignType,
        assignedIdRequest: template.assignedId,
        approvalType: template.approvalType,
        approvalIdRequest: template.approvalId
      };
      baseModal.showBaseModal();
    },
    updateStatus(item, status) {
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      TaskTemplateService.updateStatus(item.id, status).then(() => {
        this.paginationDataLoad();
      }).finally(() => {
        Swal.close();
        this.loadTemplate()
      })
    },
    onSaved() {
      const baseModal = this.$refs.templateModalRef as typeof BaseModal
      baseModal.hideBaseModal();
      this.paginationDataLoad();
      this.loadTemplate()
    }

  }
})
