
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "TaskTemplateStatus",
  props: {
    code: {type: String, required: true}
  },
  setup(props) {
    const data = ref({name: '', clazz: ''})
    const buildLabel = (code: string) => {
      switch (code) {
        case 'DEACTIVATED':
          data.value = {name: 'Archived', clazz: 'secondary'}
          break
        case 'ACTIVE':
          data.value = {name: 'Active', clazz: 'primary'}
          break

      }
    }
    buildLabel(props.code);
    watch(() => props.code, (cb) => {
      buildLabel(cb);
    })

    return {
      data
    }
  }
})
