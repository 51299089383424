
import {computed, defineComponent, ref} from "vue";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import QuickAction from "@/components/base/action/QuickAction.vue";
import store from "@/store";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import ShortPageForm from "@/views/doc/ShortPageForm.vue";
import Panel from "@/components/base/panel/Panel.vue";
import Swal from "sweetalert2";
import PageService from "@/core/services/DocService";
import HelpTitle from "@/components/base/HelpTitle.vue";

export default defineComponent({
  name: "DocCollections",
  components: {Panel, ShortPageForm, KtDatatable, QuickAction, HelpTitle},
  props: {
    workspaceId: {type: String},
  },
  setup(props) {
    const state = ref({title: '', show: false, id: null})
    const model = ref<any>({
      name: '',
      type: '',
      body: '',
      parentId: '',
      description: '',
      status: '',
      workspaceIdRequest: '',
      config: {allowFile: true, allowFaq: true}
    })
    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]);
    const listPage = computed(() => store.state.DocModule.pages)
    const filterObject = ref({workspaceId: props.workspaceId, type: 'COLLECTION'})
    return {
      listPage,
      state,
      model,
      tableHeader,
      filterObject,
      ...LoadFilterObjects(Actions.LOAD_PAGES, filterObject.value, ['createdBy'])
    }
  },
  watch: {
    workspaceId(cb) {
      this.filterObject.workspaceId = cb;
      this.updateFilterObject(this.filterObject)
    }
  },
  methods: {
    onClosePanel() {
      this.state.show = false;
    },
    onAdd(type) {
      this.model.name = '';
      this.model.type = type;
      this.model.body = '';
      this.model.status = 'DRAFT';
      this.model.description = ''
      this.state.title = 'Add Collection'
      this.model.workspaceIdRequest = this.workspaceId
      this.model.config = {allowFile: true, allowFaq: true}
      this.state.show = true
      this.state.id = null
    },
    onEdit(page) {
      this.model.name = page.name;
      this.model.type = page.type;
      this.model.parentId = page.parentId
      this.model.body = page.body;
      this.model.status = page.staus;
      this.model.description = page.description
      this.model.config = page.config ? page.config : {allowFile: true, allowFaq: true}
      this.state.title = 'Edit Collection'
      this.state.show = true
      this.state.id = page.id
    },
    onSavePage() {
      this.paginationLoad()
      this.onClosePanel()
    },

    onDeleteItem(page) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await PageService.delete(page.id).then(() => {
            store.commit(Mutations.DELETE_PAGE_ITEM, page)
            this.paginationLoad()
          })
        }
      })
    }
  },

})
