
import {computed, defineComponent, ref} from "vue";
import {useGroupStore} from "@/store/group.store";
import {PinaFilterObjects} from "@/core/composite/composite";
import QuickAction from "@/components/base/action/QuickAction.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import Swal from "sweetalert2";
import TeamService from "@/core/services/GroupService";
import TeamForm from "@/views/team/TeamForm.vue";

export default defineComponent({
  name: "MetricGroups",
  components: {TeamForm, BaseModal, KtDatatable, QuickAction},
  props: {
    workspaceId: {type: String}
  },
  setup(props) {
    const state = ref<any>({name: 'Add Team', id: '', team: {name: '', type: 'METRIC', groupIdRequest: ''}})
    const headers = [
      {
        name: 'Name',
        key: 'name',
        sortable: false
      },
      {
        name: '',
        key: 'actions',
        sortable: false
      }
    ];
    const filterObjects = ref({groupId: props.workspaceId, type: 'METRIC'})
    const store = useGroupStore();
    const page = computed(() => store.page);
    return {
      state,
      filterObjects,
      headers,
      ...PinaFilterObjects(store, filterObjects.value),
      page
    }
  },
  watch: {
    workspaceId(cb) {
      this.filterObjects.groupId = cb;
      this.updateFilterObject(this.filterObjects)
    }
  },
  methods: {
    add() {
      this.state.id = '';
      this.state.name = 'Create Metric Group';
      this.state.team = {name: '', type: 'METRIC', groupIdRequest: this.workspaceId}
      const base = this.$refs.teamFormRef as typeof BaseModal
      base.showBaseModal();
    },
    onEdit(row) {
      this.state.id = row.id;
      this.state.name = 'Edit Metric Group';
      this.state.team = {name: row.name, type: 'METRIC', groupIdRequest: this.workspaceId}
      const base = this.$refs.teamFormRef as typeof BaseModal
      base.showBaseModal();
    },
    onSave() {
      this.paginationDataLoad()
      const base = this.$refs.teamFormRef as typeof BaseModal
      base.hideBaseModal();
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          await TeamService.delete(id).then(() => {
            this.paginationDataLoad()
          })
        }
      })
    }
  }
})
